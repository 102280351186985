import React from "react";
import styled from "styled-components";

import { v4 as uuid } from "uuid";
import { $, _ } from "js/vendor";
import moment from "moment";
import { API_ERROR_CODE, ElementTextBlockPositionType, AssetType } from "common/constants";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import * as geom from "js/core/utilities/geom";
import isConnected from "js/core/utilities/isConnected";
import Api from "js/core/api";
import { isOfflinePlayer, isRenderer, getStaticUrl } from "js/config";
import { sanitizeIframe } from "js/core/utilities/dompurify";

import { SVGRectElement } from "../base/SVGElement";
import { BaseElement } from "../base/BaseElement";
import { VideoElementDefaultOverlay, VideoPropertyPanel } from "../../Editor/ElementPropertyPanels/VideoUI";

const logger = getLogger(LogGroup.ELEMENTS);

const LabelText = styled.h1`
    font-size: 42px;
    color: #ffffff;
    font-weight: bold;
`;

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const PreviewImage = styled.img.attrs(({ bounds, imageSize }) => {
    // Fill aspect
    if (bounds.aspectRatio > imageSize.aspectRatio) {
        return { style: { width: "100%" } };
    }

    return { style: { height: "100%" } };
})`
    position: absolute;
    top: 0;
    left: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const PreviewLogoContainer = styled.a`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    pointer-events: all;
`;

const PreviewLogo = styled.img.attrs(({ shadowColor }) => ({
    style: {
        filter: `drop-shadow(0px 0px 20px ${shadowColor})`
    }
}))`
    width: 200px;
    pointer-events: all;
`;

const VideoClickShield = styled.div.attrs(({ bounds, shouldRenderVideo }) => ({
    style: {
        width: bounds.width * .333,
        height: bounds.height - (shouldRenderVideo ? 100 : 0),
        left: bounds.width - bounds.width * .333,
    }
}))`
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    z-index: 100000;
    cursor: pointer;
    pointer-events: auto;

    i {
        display: none;
        font-size: 60px;
        color: white;
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    }

    &:hover {
        i {
            display: block;
        }
    }
`;

export class Video extends BaseElement {
    getElementPropertyPanel() {
        return VideoPropertyPanel;
    }

    get _canSelect() {
        return true;
    }

    get isInteractive() {
        return true;
    }

    get interactiveAction() {
        return {
            type: "video",
        };
    }

    getElementDefaultOverlay() {
        return VideoElementDefaultOverlay;
    }

    get _showDefaultOverlay() {
        return _.isEmpty(this.model.videoId) && _.isEmpty(this.model.videoAssetId);
    }

    get publicVideoUrl() {
        if (this.model.videoId) {
            if (this.model.videoId.startsWith("http")) {
                return this.model.videoId;
            } else {
                return "https://www.youtube.com/watch?v=" + this.model.videoId; // migration for old youtube video ids
            }
        } else {
            return null;
        }
    }

    get videoType() {
        if (this.model.videoAssetId) return "upload";
        if (!this.publicVideoUrl) return "none";
        if (this.publicVideoUrl.contains("vimeo")) return "vimeo";
        if (this.publicVideoUrl.contains("youtube") || this.publicVideoUrl.contains("youtu.be")) return "youtube";
        return "none";
    }

    get isAvailableOffline() {
        return isOfflinePlayer && this.videoType === "upload";
    }

    get startTime() {
        return this.model.startTime || 0;
    }

    get autoPlay() {
        return this.canvas?.isPlayback ? (!!this.model?.autoPlay) : false;
    }

    get loop() {
        return this.model?.loop || false;
    }

    get muted() {
        return (
            !!this.model?.muted ||
            (
                // Autoplay audio will come from the presenter view if active
                this.autoPlay &&
                !!this.canvas?.playerView?.activePresenter
            )
        );
    }

    get playbackRate() {
        return this.model?.playbackRate || 1;
    }

    get isCacheValid() {
        if (isRenderer) {
            return false;
        }

        if (!this.cachedHTML) {
            return false;
        }

        if (this.cacheValidTill && this.cacheValidTill < moment().valueOf()) {
            return false;
        }

        if (this.cachedVideoId !== this.model.videoId ||
            this.cachedVideoAssetId !== this.model.videoAssetId ||
            this.cachedStartTime !== this.model.startTime ||
            this.cachedMuted !== this.model.muted ||
            this.cachedLoop !== this.model.loop ||
            this.cachedActivePresenter !== this.canvas?.playerView?.activePresenter
        ) {
            return false;
        }

        return true;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    saveCache(html, cacheTTLMs = null) {
        this.cachedHTML = html;
        this.cacheValidTill = cacheTTLMs ? moment().valueOf() + cacheTTLMs : null;
        this.cachedVideoId = this.model.videoId;
        this.cachedVideoAssetId = this.model.videoAssetId;
        this.cachedStartTime = this.model.startTime;
        this.cachedMuted = this.model.muted;
        this.cachedLoop = this.model.loop;
        this.cachedActivePresenter = this.canvas?.playerView?.activePresenter;
    }

    getCanvasMargins() {
        let fullBleed = this.model.fullBleed;
        if (this.canvas.model.layout.showElementAttribution || this.canvas.model.layout.elementTextBlockPosition == ElementTextBlockPositionType.INLINE) {
            fullBleed = false;
        }
        if (fullBleed) {
            if (this.canvas.layouter.showFooter) {
                return { left: 0, top: 0, bottom: 10, right: 0 };
            } else {
                return { left: 0, top: 0, bottom: 0, right: 0 };
            }
        } else {
            return { left: 50, top: 50, bottom: 50, right: 50 };
        }
    }

    getOriginalVideoSize() {
        return this.originalVideoSize || null;
    }

    getYoutubeSlug(url) {
        // Regex for slug extract from:
        //   https://stackoverflow.com/a/34833500/14795819
        const regexExtractYoutubeSlug = /(?:https?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtube|youtu|youtube-nocookie)\.(?:com|be)\/(?:watch\?v=|watch\?.+&v=|embed\/|v\/|.+\?v=)?([^&=\n%\?]{11})/;
        const regexResults = regexExtractYoutubeSlug.exec(url);
        // We take the 2nd result because we have to match the `https://youtube` part
        //   separately to avoid a false positive match on the first `//`
        const videoSlug = regexResults?.[1];
        return videoSlug;
    }

    async _load() {
        this.labelText = null;

        // Only used in renderer
        this.previewImageUrl = null;
        this.previewImageSize = null;

        if (this.isTryingLayout) {
            return;
        }

        if (this.videoType === "none") {
            return;
        }

        if (!this.isAvailableOffline && !isConnected.connected) {
            this.labelText = "Video is not available offline";
            return;
        }

        if (this.isCacheValid) {
            return;
        }

        if (this.videoType === "upload") {
            try {
                const asset = await ds.assets.getAssetById(this.model.videoAssetId, AssetType.VIDEO);
                if (isRenderer) {
                    const previewAssetId = asset.get("previewAssetId");
                    if (previewAssetId) {
                        const previewAsset = await ds.assets.getAssetById(previewAssetId, AssetType.IMAGE);
                        this.previewImageUrl = await previewAsset.getURL();
                        this.previewImageSize = new geom.Size(previewAsset.get("w"), previewAsset.get("h"));
                        return;
                    }

                    this.labelText = "Uploaded video";
                    return;
                }

                const url = await asset.getURL();
                this.originalVideoSize = new geom.Size(800, 450); // Default it to 16:9 size
                const html = `<video width="100%" height="100%" controls disablePictureInPicture playsinline controlsList="nodownload noplaybackrate" ${this.muted ? "muted" : ""} ${this.autoPlay ? "autoplay" : ""} ${this.loop ? "loop" : ""}><source src="${url}#t=${this.startTime}" type="video/mp4"></source></video>`;

                // Briefly create a dom element so we can get the video metadata
                const $video = $(html);

                // Set autoplay to false so the temp video doesn't start playing on Safari
                $video[0].autoplay = false;
                $video.on("loadedmetadata", event => {
                    const { videoWidth, videoHeight } = $video[0];
                    this.originalVideoSize = new geom.Size(videoWidth, videoHeight);
                    $video.remove();
                });

                this.saveCache(html, 5 * 60 * 1000);
                return;
            } catch (err) {
                logger.error(err, "[Video] failed to load uploaded video", { videoAssetId: this.model.videoAssetId });
                this.labelText = "Video not found";
            }
        }

        const encodedUrl = encodeURIComponent(this.publicVideoUrl);
        const oembedUrl = this.videoType === "vimeo"
            ? `https://www.vimeo.com/api/oembed.json?url=${encodedUrl}&autoplay=${this.autoPlay ? 1 : 0}&loop=${this.loop ? 1 : 0}&byline=false&portrait=false&title=false&muted=${this.muted ? 1 : 0}`
            : `https://www.youtube.com/oembed?url=${encodedUrl}&format=json`;

        try {
            let { html, width, height, thumbnail_url, thumbnail_width, thumbnail_height } = await Api.oembed.post({ url: oembedUrl });
            if (isRenderer) {
                this.previewImageUrl = thumbnail_url;
                this.previewImageSize = new geom.Size(thumbnail_width, thumbnail_height);
                return;
            }

            this.originalVideoSize = new geom.Size(width, height);
            if (this.videoType === "youtube") {
                const videoSlug = this.getYoutubeSlug(this.publicVideoUrl);
                // Youtube looping requires a playlist, so we use the video itself as the playlist
                //   Additionally, this hides the Youtube's related videos display, which is a nice bonus when not looping
                const playlistParam = videoSlug ? `&playlist=${videoSlug}` : "";
                // Oembed doesn't pass through Youtube params, so fix them now
                html = html.replace("feature=oembed", `feature=oembed&enablejsapi=1&rel=0&modestbranding=1&mute=${this.muted ? 1 : 0}&autoplay=${this.autoPlay.toString()}&start=${this.startTime}&loop=${this.loop.toString()}${playlistParam}`);
            } else {
                html = html.replace('" width', `#t=${this.canvas.isPlayback ? this.startTime : 0}s" width`);
            }

            // Setting with and height of the iframe to 100%
            html = html.replace(/height=[^s]+/, "");
            html = html.replace(/width=[^s]+/, `width=\"100%\" height=\"100%\"`);

            // based on vimeo: https://help.vimeo.com/hc/en-us/articles/12426486963857-Autoplay-and-loop-embedded-videos
            if (this.videoType === "vimeo" && this.autoPlay) {
                html = html.replace("<iframe", `<iframe allow='autoplay'`);
            }

            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const iframe = doc.querySelector("iframe");

            if (iframe && !iframe.style.border) {
                iframe.style.border = "0px";
            }
            const modifiedHtml = doc.documentElement.outerHTML;

            this.saveCache(modifiedHtml, null);
        } catch (err) {
            logger.error(err, "[Video] failed to load embedded video", { publicVideoUrl: this.publicVideoUrl });
            if (err.code && err.code === API_ERROR_CODE.FORBIDDEN) {
                this.labelText = "The permissions on the video do not allow oembed";
            } else {
                this.labelText = "Could not load video";
            }
        }
    }

    _build() {
        this.background = this.addElement("background", () => SVGRectElement);
        this.background.layer = -1;
    }

    _calcProps(props, options) {
        const { size } = props;

        const backgroundProps = this.background.calcProps(size);
        backgroundProps.bounds = new geom.Rect(0, 0, size);

        return { size };
    }

    _applyColors() {
        this.background.colorSet.fillColor = this.palette.getColor("black");
    }

    handlePlaybackClick = event => {
        event.preventDefault();
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        if (this.canvas.isPlayback) {
            // HACK: Needed because PlayerView.onClick() is in a different React
            //   tree, so stopPropagtion doesn't work for those events
            this.canvas.playerView.blockClickUntilNextFrame();

            if (this.canvas.options.advanceToSlide) {
                this.canvas.options.advanceToSlide(1);
            } else if (this.canvas.playerView) {
                this.canvas.playerView.$container.trigger(event);
                this.canvas.playerView.goNextSlide(true);
            }
        }
    }

    renderChildren(transition) {
        const children = super.renderChildren(transition);

        if (isRenderer) {
            const logoUrl = this.videoType === "youtube"
                ? getStaticUrl("/images/ui/vendor/youtube-logo.png")
                : this.videoType === "vimeo"
                    ? getStaticUrl("/images/ui/vendor/vimeo-logo.png")
                    : null;

            children.push(
                <Container key="video">
                    {this.previewImageUrl && <>
                        <PreviewImage
                            bounds={this.calculatedProps.bounds}
                            imageSize={this.previewImageSize}
                            src={this.previewImageUrl}
                            alt="video"
                            onLoad={this.getImageOnLoadPromiseResolver(this.previewImageUrl)}
                        />
                        {logoUrl && <PreviewLogoContainer href={this.publicVideoUrl}>
                            <PreviewLogo
                                src={logoUrl}
                                shadowColor={this.videoType === "youtube" ? "white" : "black"}
                                onLoad={this.getImageOnLoadPromiseResolver(logoUrl)}
                            />
                        </PreviewLogoContainer>
                        }
                    </>}
                    {this.labelText && <LabelText>{this.labelText}</LabelText>}
                </Container>
            );
            return children;
        }

        const shouldRenderVideo = this.isCacheValid && this.canvas.isCurrentCanvas;
        if (shouldRenderVideo) {
            const style = {};

            // Removing the ugly white borders for vimeo embeds
            if (this.videoType === "vimeo") {
                style.top = "-2px";
                style.left = "-2px";
                style.width = "calc(100% + 2px)";
                style.height = "calc(100% + 2px)";
            }
            children.push(<Container key="video"
                onClick={()=>this.canvas.possiblyAdvanceAnimation(this)}
                style={style} dangerouslySetInnerHTML={{ __html: sanitizeIframe(this.cachedHTML) }} />);
            if (this.videoType === "upload") {
                this.canvas.layouter.runPostRender(() => {
                    this.DOMNode.querySelector("video").defaultPlaybackRate = this.playbackRate;
                    this.DOMNode.querySelector("video").playbackRate = this.playbackRate;
                });
            }
        } else if (this.labelText) {
            children.push(<Container><LabelText>{this.labelText}</LabelText></Container>);
        }

        if (this.canvas.isPlayback && !this.options.noPlaybackArrow) {
            const { bounds } = this.calculatedProps;
            children.push(<VideoClickShield
                key={uuid()}
                bounds={bounds}
                shouldRenderVideo={shouldRenderVideo}
                onClick={this.handlePlaybackClick}
            >
                <i className="micon">arrow_forward</i>
            </VideoClickShield>);
        }

        return children;
    }

    _prepareToShowElement() {
        if (this.isAvailableOffline || isConnected.connected) {
            _.defer(async () => {
                this._stopElement();
                // Reload if cache is invalid
                await this.load();
                this._autoPlayElement();
                this.refreshElement();
            });
        }
    }

    _autoPlayElement() {
        if (!this.autoPlay) {
            return;
        }

        const iframe = this.DOMNode?.querySelector("iframe")?.contentWindow;
        if (iframe) {
            if (this.videoType === "youtube") {
                iframe.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
            }
            if (this.videoType === "vimeo") {
                iframe.postMessage('{"method":"play"}', "*");
            }
        } else {
            let playPromise = this.DOMNode?.querySelector("video")?.play();
            if (playPromise !== undefined) {
                playPromise.catch(err=>{});
            }
        }
    }

    _stopElement() {
        // don't fire off async refreshCanvas if this was called because we are closing the player
        if (this.canvas.isPlayback && this.canvas.playerView && this.canvas.playerView.closing) {
            return;
        }

        const iframe = this.DOMNode?.querySelector("iframe")?.contentWindow;
        if (iframe) {
            if (this.videoType === "youtube") {
                iframe.postMessage('{"event":"command","func":"stopVideo","args":""}', "*");
            }

            if (this.videoType === "vimeo") {
                iframe.postMessage('{"method":"pause"}', "*");
            }
        } else {
            this.DOMNode?.querySelector("video")?.pause();
        }

        this.refreshElement();
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get animationElementName() {
        return "Video";
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    _exportToSharedModel() {
        const { videoAssetId: assetId, ...assetProps } = this.model;
        if (!assetProps.originalSize) {
            assetProps.originalSize = { width: this.originalVideoSize?.width, height: this.originalVideoSize?.height };
        }
        return { assets: [{ value: assetId, props: assetProps, type: AssetType.VIDEO }] };
    }

    _importFromSharedModel(model) {
        const { assets } = model;
        if (!assets?.length) return;

        const videoAsset = assets.filter(asset => [AssetType.VIDEO, AssetType.STOCK_VIDEO].includes(asset.type))[0];
        if (videoAsset) {
            return {
                videoAssetId: videoAsset.value,
                ...(videoAsset.props || {})
            };
        }
    }
}

export const elements = {
    Video
};

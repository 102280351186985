import React, { Component } from "react";

import { _ } from "js/vendor";
import { Dropdown } from "js/Components/Dropdown";
import { ImageOptionList } from "js/Components/ImageOptionList";
import { Popup, PopupContainer, PopupContent } from "js/Components/Popup";
import { ToggleIcon, ToggleSwitch } from "js/Components/ToggleSwitch";
import { WithLabel } from "js/Components/WithLabel";
import { FlexSpacer } from "js/react/components/Gap";
import { FlexBox, ScrollBox } from "js/react/components/LayoutGrid";
import { HorizontalPropertyList, PropertyPanelContainer, PropertySection, PropertySectionHeader } from "js/EditorComponents/PropertyPanel";
import { MenuItemDetails, MenuItemWithDetails } from "js/Components/Menu";
import { ControlBarPosition } from "common/constants";
import { Button } from "js/Components/Button";
import { Icon } from "js/Components/Icon";
import { ShowConfirmationDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { NumericStepper } from "js/Components/NumericStepper";

import { FlexGridLayout } from "../../../elements/elements/LayoutContainers/GridLayoutEngines/FlexGridLayout";
import { ColorPicker } from "../../EditorComponents/ColorPickers/ColorPicker";
import { PhotoCollageLayouts } from "../../../elements/elements/LayoutContainers/GridLayoutEngines/LayoutDefs";
import { CollectionElementControlBar } from "../../ElementControlBars/CollectionElementControlBar";
import { LayoutEngineType } from "../../../elements/elements/LayoutContainers/GridLayoutContainer";
import { InfographicElementTypes } from "../../../elements/elements/Dashboard/InfographicManager";
import { ControlBar, ControlBarGroup } from "../../ElementControlBars/Components/ControlBar";

export function setGridLayout(element, layout) {
    let mergeCells = () => {
        if (element.itemCount == 2 &&
            element.itemElements.filter(item => item.childElement.showImage && !item.childElement.showText).length == 1 &&
            element.itemElements.filter(item => item.childElement.showText && !item.childElement.showImage).length == 1
        ) {
            let item = element.itemElements.find(item => item.childElement.showImage);
            let item2 = element.itemElements.find(item => item.childElement.showText);

            let itemModel = item.model.childElement;
            let item2Model = item2.model.childElement;

            itemModel.text = _.cloneDeep(item2Model.text);
            itemModel.textAlign = item2Model.textAlign;
            itemModel.verticalAlign = item2Model.verticalAlign;
            itemModel.backdropPadding = item2Model.backdropPadding;
            delete itemModel.userPositionX;
            delete itemModel.userPositionY;

            element.itemCollection.remove(item2.model);

            element.setGridLayout(element.getLayoutEngine().getDefaultLayout(1));
            element.saveModel();
        }
    };

    let splitCells = imagePosition => {
        let imageCell, textCell;

        if (element.itemCount == 1) {
            imageCell = element.itemElements[0].model;

            textCell = {
                componentType: InfographicElementTypes.TEXT_AND_IMAGE,
                childElement: {
                    text: imageCell.childElement.text,
                    textAlign: imageCell.childElement.textAlign,
                    verticalAlign: imageCell.childElement.verticalAlign,
                }
            };
            delete imageCell.childElement.text;
            delete imageCell.childElement.textAlign;
            delete imageCell.childElement.verticalAlign;
        } else {
            imageCell = element.itemElements.find(item => item.childElement.showImage).model;
            textCell = element.itemElements.find(item => item.childElement.showText).model;
        }

        element.itemCollection = [];
        switch (imagePosition) {
            case "left":
                element.setGridLayout(element.getLayoutEngine().layouts[1]);
                element.itemCollection = [imageCell, textCell];
                break;
            case "right":
                element.setGridLayout(element.getLayoutEngine().layouts[1]);
                element.itemCollection = [textCell, imageCell];
                break;
            case "top":
                element.setGridLayout(element.getLayoutEngine().layouts[2]);
                element.itemCollection = [imageCell, textCell];
                break;
            case "bottom":
                element.setGridLayout(element.getLayoutEngine().layouts[2]);
                element.itemCollection = [textCell, imageCell];
                break;
        }

        element.saveModel();
    };

    let transition = false;

    if (layout.cells) {
        if (layout.cells.length == 1) {
            mergeCells();
        } else if (layout.cells.length == 2) {
            // splitCells("left");
        }

        // remove any empty cells
        if (layout.cells.length < element.itemCollection.length) {
            element.itemCollection = element.itemCollection.filter(item => item.childElement);
        }

        // when the same layout is selected, rotate the items
        if (_.isEqual(layout, element.gridLayout)) {
            element.itemCollection.insert(element.itemCollection.pop(), 0);
            transition = true;
        }
    }

    element.setGridLayout(layout);

    if (layout.cells) {
        // reset the transforms of all images unless their gridBounds haven't changed
        for (let item of element.itemElements.filter(item => item.childElement?.content)) {
            if (layout.cells[item.itemIndex] && (
                layout.cells[item.itemIndex].width !== item.calculatedProps.gridBounds.width ||
                layout.cells[item.itemIndex].height !== item.calculatedProps.gridBounds.height
            )) {
                item.childElement?.content?.assetElement?.resetMediaTransform && item.childElement?.content?.assetElement?.resetMediaTransform();
            }
        }

        // delete any orphaned cells
        element.itemCollection = element.itemCollection.slice(0, layout.cells.length);
    }

    element.saveModel(transition);
}

export class GridLayoutPropertyPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            GridThumbnails: []
        };

        this.gridThumbnailsCalculatedForModel = null;
    }

    componentDidMount() {
        const { element, selectionLayerController } = this.props;

        if (element.layoutEngineType === LayoutEngineType.SINGLE_CELL) {
            selectionLayerController.setSelectedElements([element.itemElements[0]]);
            return;
        }

        this.setGridThumbnails();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.element.layoutEngineType !== this.state.thumbnailsGeneratedFor) {
            this.setGridThumbnails();
        }
        if (this.props.element.layoutEngineType === LayoutEngineType.PRESERVE_ASPECT && this.lastNumberOfItems != this.props.element.itemCount) {
            this.setGridThumbnails();
        }
    }

    setGridThumbnails = () => {
        const { element, layoutSize } = this.props;

        let layoutEngine = element.getLayoutEngine();

        let GridThumbnails;
        switch (element.layoutEngineType) {
            case LayoutEngineType.PRESERVE_ASPECT:
                GridThumbnails = layoutEngine.renderThumbnails(element.itemElements, element.calculatedProps.bounds.size);
                this.lastNumberOfItems = element.itemCount;
                break;
            case LayoutEngineType.HEADLINE:
                GridThumbnails = layoutEngine.renderThumbnails();
                break;
            case LayoutEngineType.DASHBOARD:
                GridThumbnails = layoutEngine.renderThumbnails(layoutEngine.layouts.filter(item => item.favorite), layoutSize);
                break;
            case LayoutEngineType.CUSTOM:
                GridThumbnails = null;
                break;
            case LayoutEngineType.FLEX:
            default:
                GridThumbnails = layoutEngine.renderThumbnails(layoutEngine.layouts.filter(item => item.favorite), layoutSize, layoutSize);
        }
        this.setState({ GridThumbnails, thumbnailsGeneratedFor: element.layoutEngineType });
    }

    handleAddCell = () => {
        const { element } = this.props;
        element.addItem();
        element.saveModel();
    }

    renderAllLayouts = closePopup => {
        const { element } = this.props;

        const groupedLayouts = _.groupBy(PhotoCollageLayouts, item => item.cells.length);
        const keys = Object.keys(groupedLayouts);

        return (
            <PropertySection>
                <ScrollBox>
                    <FlexBox vertical gap={10}>
                        {keys.map((key, index) => {
                            const layouts = groupedLayouts[key];
                            return (
                                <div key={index}>
                                    <label>{layouts[0].cells.length} {"Cell".pluralize(layouts[0].cells.length > 1)}</label>
                                    <ImageOptionList gap={5} cols={6} size={70}
                                        onChange={value => {
                                            element.setGridLayout(value);
                                            element.saveModel(true);
                                            closePopup();
                                        }}
                                    >
                                        {element.layoutEngine.renderThumbnails(layouts)}
                                    </ImageOptionList>
                                </div>
                            );
                        })}
                    </FlexBox>
                </ScrollBox>
            </PropertySection>
        );
    }

    render() {
        const {
            element,
            allowFullBleed = true,
            allowGutter = true,
            availableLayoutEngines = [LayoutEngineType.FLEX],
            layoutCols = 4,
            isPopupMenu,
            popupIconSize = 70,
            showMoreGridOptions
        } = this.props;
        const { GridThumbnails } = this.state;

        return (
            <>
                <PropertySection>
                    <PropertySectionHeader label="Grid">
                        {availableLayoutEngines.length > 1 &&
                            <Dropdown value={element.layoutEngineType} small
                                onChange={async value => {
                                    if (element.layoutEngineType === LayoutEngineType.PRESERVE_ASPECT && value === LayoutEngineType.CUSTOM) {
                                        if (await ShowConfirmationDialog({ title: "Preserve aspect ratio grids can not be converted to custom grids.", message: "Changing to a custom grid from the preserve aspect ratio will reset the grid to a single cell. Are you sure you want to continue (this action can be undone)?" }) == false) {
                                            return;
                                        }
                                    }

                                    element.setLayoutEngineType(value);
                                    await element.saveModel();
                                    this.setGridThumbnails();
                                }}
                            >
                                {availableLayoutEngines.includes(LayoutEngineType.SIDEBAR) &&
                                    <MenuItemWithDetails value={LayoutEngineType.SIDEBAR}>
                                        Flex Grid
                                        <MenuItemDetails>A flexible grid that fills cells with images - cropping them if necessary.</MenuItemDetails>
                                    </MenuItemWithDetails>
                                }
                                {availableLayoutEngines.includes(LayoutEngineType.FLEX) &&
                                    <MenuItemWithDetails value={LayoutEngineType.FLEX}>
                                        Flex Grid
                                        <MenuItemDetails>A flexible grid that fills cells with images - cropping them if necessary.</MenuItemDetails>
                                    </MenuItemWithDetails>
                                }
                                {availableLayoutEngines.includes(LayoutEngineType.HEADLINE) &&
                                    <MenuItemWithDetails value={LayoutEngineType.HEADLINE}>
                                        Headline
                                        <MenuItemDetails>Layout for a headline with an image.</MenuItemDetails>
                                    </MenuItemWithDetails>
                                }
                                {availableLayoutEngines.includes(LayoutEngineType.DASHBOARD) &&
                                    <MenuItemWithDetails value={LayoutEngineType.DASHBOARD}>
                                        Dashboard
                                        <MenuItemDetails>Layout for a dashboard with multiple cells.</MenuItemDetails>
                                    </MenuItemWithDetails>
                                }
                                {availableLayoutEngines.includes(LayoutEngineType.PRESERVE_ASPECT) &&
                                    <MenuItemWithDetails value={LayoutEngineType.PRESERVE_ASPECT}>
                                        Preserve Aspect Ratio Grid
                                        <MenuItemDetails>Preserves the original aspect ratio of images without any cropping.</MenuItemDetails>
                                    </MenuItemWithDetails>
                                }
                                {availableLayoutEngines.includes(LayoutEngineType.STACK_DIAGRAM) &&
                                    <MenuItemWithDetails value={LayoutEngineType.STACK_DIAGRAM}>
                                        Stack Diagram
                                        <MenuItemDetails>Layout for a stack diagram with multiple cells.</MenuItemDetails>
                                    </MenuItemWithDetails>
                                }
                                {availableLayoutEngines.includes(LayoutEngineType.CUSTOM) &&
                                    <MenuItemWithDetails value={LayoutEngineType.CUSTOM}>
                                        Custom Grid
                                        <MenuItemDetails>Set custom grid settings for more flexibility.</MenuItemDetails>
                                    </MenuItemWithDetails>
                                }
                            </Dropdown>
                        }
                    </PropertySectionHeader>
                    {element.layoutEngineType != LayoutEngineType.CUSTOM &&
                        <ImageOptionList gap={5}
                            size={isPopupMenu ? popupIconSize : null}
                            cols={layoutCols}
                            onChange={value => setGridLayout(element, value)}
                        >
                            {GridThumbnails}
                        </ImageOptionList>
                    }
                    {element.layoutEngineType == LayoutEngineType.CUSTOM && (
                        <HorizontalPropertyList>
                            <WithLabel label="Grid Columns">
                                <NumericStepper value={element.model.customGridCols ?? 12}
                                    min={4} max={60}
                                    onChange={value => {
                                        element.model.customGridCols = value;
                                        element.model.gridLayout.cols = value;
                                        FlexGridLayout.fillEmptySlots(element);
                                        element.saveModel();
                                    }}
                                />
                            </WithLabel>
                            <WithLabel label="Rows">
                                <NumericStepper value={element.model.customGridRows ?? 12}
                                    min={4} max={60}
                                    onChange={value => {
                                        element.model.customGridRows = value;
                                        element.model.gridLayout.rows = value;

                                        FlexGridLayout.fillEmptySlots(element);
                                        element.saveModel();
                                    }}
                                />
                            </WithLabel>
                        </HorizontalPropertyList>
                    )}
                    {element.layoutEngineType == LayoutEngineType.FLEX &&
                        <Popup label="More Grids" showArrow>
                            <PopupContent>
                                {closePopup => (
                                    <PopupContainer>
                                        <PropertyPanelContainer>
                                            {this.renderAllLayouts(closePopup)}
                                        </PropertyPanelContainer>
                                    </PopupContainer>
                                )}
                            </PopupContent>
                        </Popup>
                    }
                </PropertySection>
                <PropertySection>
                    <HorizontalPropertyList>
                        {allowGutter &&
                            <WithLabel label="Gutter" below center small>
                                <ToggleIcon icon="grid_view" iconOff="window"
                                    value={element.showGutter}
                                    disabled={element.itemElements.length == 1 && !element.fullBleed}
                                    onChange={value => element.updateModel({ showGutter: value }, { refreshStyles: true })} />
                            </WithLabel>
                        }
                        {allowFullBleed &&
                            <WithLabel label="Full Bleed" below center small>
                                <ToggleIcon icon="aspect_ratio"
                                    value={element.fullBleed}
                                    onChange={value => element.updateModel({ fullBleed: value }, { refreshStyles: true })} />
                            </WithLabel>
                        }
                        {(allowFullBleed || allowGutter) && <FlexSpacer />}
                        <WithLabel below center small label="Cell Color">
                            <ColorPicker canvas={element.canvas} value={element.model.cellColor}
                                showNone showBackgroundColors showColorful allowColorOnColor
                                onChange={color => {
                                    element.model.cellColor = color;
                                    for (let cell of element.itemElements) {
                                        cell.model.cellColor = color;
                                    }
                                    element.saveModel(false, true);
                                }}
                            />
                        </WithLabel>
                        <WithLabel below center small label="Border">
                            <ColorPicker canvas={element.canvas} value={element.model.borderColor}
                                showNone showBackgroundColors allowColorOnColor
                                onChange={color => element.updateModel({ borderColor: color })}
                                bottomSection={
                                    <PropertySection>
                                        <WithLabel label="Show Shadow">
                                            <ToggleSwitch value={element.model.borderShadow}
                                                onChange={value => element.updateModel({ borderShadow: value })} />
                                        </WithLabel>
                                    </PropertySection>
                                }
                            />
                        </WithLabel>
                    </HorizontalPropertyList>
                </PropertySection>

            </>
        );
    }
}

export class GridLayoutContainerControlBar extends CollectionElementControlBar {
    get suppressPrimaryControlBar() {
        return true;
    }

    handleAddCell = () => {
        const { element } = this.props;

        if (element.layoutEngineType == LayoutEngineType.CUSTOM) {
            ShowWarningDialog({ title: "Adding Cells to a Custom Grid", message: "To add cells to a custom grid, please drag out new cells using the Add Cell handles in the grid." });
        } else if (element.layoutEngineType == LayoutEngineType.PRESERVE_ASPECT) {
            element.addItem();
        } else {
            element.setGridLayout(element.getLayoutEngine().getDefaultLayout(element.itemCount + 1));
        }
        element.saveModel();
    }

    render() {
        const { element, elementPanelElement } = this.props;

        if (elementPanelElement && elementPanelElement.isChildOf(element)) {
            return null;
        }

        let ContainerPropertyPanel = element.getElementPropertyPanel();

        return (
            <ControlBar position={ControlBarPosition.CANVAS}>
                <ControlBarGroup>
                    <Button blue onClick={this.handleAddCell} disabled={element.itemCount > element.maxItemCount - 1}>
                        <Icon>add</Icon>Add Cell
                    </Button>
                    <Popup icon="grid_view">
                        <PopupContent>
                            {closePopup => (
                                <ContainerPropertyPanel {...this.props} element={element} isPopupMenu />
                            )}
                        </PopupContent>
                    </Popup>
                </ControlBarGroup>
            </ControlBar>
        );
    }
}
